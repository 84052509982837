<template src="./Nominee.html">

</template>

<script>
import jobsService from "@/services/Jobs";

export default {
  name: "Nominee",
  props:{
    nomineeData:{
      type: Object,
    }
  },
  data(){
    return{
      nominee: this.nomineeData,
      jobOfferApplicationPhase: !this.nomineeData.jobOfferApplicationPhase? null:this.nomineeData.jobOfferApplicationPhase.order,
    }
  },
  updated() {
    this.jobOfferApplicationPhase = !this.nomineeData.jobOfferApplicationPhase? null:this.nomineeData.jobOfferApplicationPhase.order;
  },
  methods:{
    redirectProfileUser(path, follow) {
      let routeData = this.$router.resolve({
        name: path,
        params: { id: follow }
      });
      window.open(routeData.href, "_blank");
    },
    showToastError(e){
      this.$buefy.toast.open({
        duration: 5000,
        message: e.response.data.message,
        position: 'is-bottom',
        type: 'is-danger'
      })
    },
    showToastSuccess(message){
      this.$buefy.toast.open({
        duration: 5000,
        message: message,
        position: 'is-bottom',
        type: 'is-success'
      })
    },
    refuse() {
      if (this.nomineeData.jobOfferApplicationPhase.order && this.nomineeData.jobOfferApplicationPhase.order >= 3) {
        this.$buefy.dialog.prompt({
          title: 'Feedback',
          message: 'Has rechazado el proceso de un postulante antes de finalizar. Escribe tu retroalimentación al respecto.',
          inputAttrs: {
            type: 'textarea',
            placeholder: 'Escribir retroalimentación',
            value: '',
            rows: '10'
          },
          confirmText: 'Enviar feedback',
          closeOnConfirm: false,
          onConfirm: async (value, {close}) => {
            try {
              await jobsService.rejectJobApplication(this.nomineeData.id, value);
              this.showToastSuccess('Candidatura rechazada correctamente');
              close();
              this.$emit('update',(this.jobOfferApplicationPhase));
            }catch (e) {
              this.showToastError(e);
            }
          }
        })
      } else {
        this.$buefy.dialog.confirm({
          title: 'Feedback',
          message: 'Has rechazado el proceso de un postulante antes de finalizar.',
          confirmText: 'Enviar feedback',
          closeOnConfirm: true,
          onConfirm: async () => {
            try {
              await jobsService.rejectJobApplication(this.nomineeData.id);
              this.showToastSuccess('Candidatura rechazada correctamente');
              close();
              this.$emit('update',(this.jobOfferApplicationPhase));
            }catch (e) {
              this.showToastError(e);
            }
          }
        })
      }
    },
    hire() {
      this.$buefy.dialog.confirm({
        title: 'Proceso finalizado',
        message: '¡Felicitaciones! Tu oferta laboral ha finalizado. Comunícate con el usuario seleccionado para el cargo para continuar con el proceso.',
        confirmText: 'Ir al perfil',
        closeOnConfirm: true,
        onConfirm:async () => {
          try {
            const response = await jobsService.advanceJobApplication(this.nomineeData.id);
            this.nominee = response
            this.jobOfferApplicationPhase = response.jobOfferApplicationPhase.order;
            this.showToastSuccess('El postulante ha avanzado en el proceso correctamente');
            await this.$router.push({name:'ProfileUsers', params:{id:this.nomineeData.user.id}});
            close();
          }catch (e) {
            this.showToastError(e);
          }
        }
      })
    },
    async advance(){
      try {
        const response = await jobsService.advanceJobApplication(this.nomineeData.id);
        this.nominee = response;
        this.jobOfferApplicationPhase = response.jobOfferApplicationPhase.order;
        this.$emit('update',this.jobOfferApplicationPhase);
        this.showToastSuccess('El postulante ha avanzado en el proceso correctamente');
      }catch (e) {
        this.showToastError(e);
      }
    }
  }
}
</script>

<style scoped lang="scss" src="./Nominee.scss">

</style>
