<template src="./AllNominees.html">

</template>

<script>
import Nominee from "@/components/Nominee/Nominee";
import jobsService from "@/services/Jobs";
import ArrobaMedellinArrobitaNoData from '@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData';
import {mapGetters} from "vuex";

export default {
  name: "AllNominees",
  components:{
    Nominee,
    ArrobaMedellinArrobitaNoData
  },
  data(){
    return{
      jobOfferApplicationPhase: 1,
      nominees: []
    }
  },
  created() {
    this.getApplicantsJob( this.getJobApplicationPhases[0].id);
  },
  methods:{
    async getApplicantsJob(phaseId){
      try {
        const response = await jobsService.getApplicantsJob(this.$route.params.id, this.$store.getters.getUId, phaseId)
        this.nominees = response.applicants;
        this.jobOfferApplicationPhase = this.nominees[0]? this.nominees[0].jobOfferApplicationPhase.order : null;
      } catch (e) {
        console.error('error', e);
      }
    }
  },
  computed: {
    ...mapGetters({
      getJobApplicationPhases: 'getJobApplicationPhases'
    })
  },
}
</script>

<style scoped lang="scss" src="./AllNominees.scss">

</style>
